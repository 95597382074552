import React, { Component } from 'react'
import municipalika from '../images/municipalika.png'
import bharatRuralExpo from '../images/bharat_rural_expo.png'
import capex from '../images/capex.png'
import capexbg from '../images/CAPExbg.jpg'
import AAM from '../images/AAMlogo.png'
import AAMbg from '../images/AAMbg.jpg'
import UNDbg from '../images/UNDbg.jpg'
import und from '../images/und.png'
import DetailsSection from '../components/DetailsSection'
import Footer from '../components/Footer'
import Layout from '../layouts/index'
import Scroller from '../components/Scroll'
import udimg from '../images/UD.png'
import HomeSection from '../components/HomeSection'
import { withScroll } from '../components/Scroll'
import TheyTrustUs11 from '../components/TheyTrustUs11'

export class Urban extends Component {
  constructor(props) {
    super(props)
    this.state = {
      whiteLogo: this.updateHeaderColor(props.activeSection),
      activeColor: this.getActiveColor(props.activeSection),
    }
  }
  componentDidMount() {
    switch (window.location.hash) {
      case '#municipalika':
        this.props.goToSection(1)
        break
      case '#capex':
        this.props.goToSection(3)
        break
      case '#india-international-property-mart':
        this.props.goToSection(5)
        break
      case '#urban-news-digest':
        this.props.goToSection(6)
        break
      default:
        this.props.goToSection(0)
        break
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { whiteLogo, activeColor: activeColorState } = this.state
    if (nextProps.activeSection !== this.props.activeSection) {
      const white = this.updateHeaderColor(nextProps.activeSection)
      if (white !== whiteLogo) {
        this.setHeaderColor(white)
      }
      const activeColor = this.getActiveColor(nextProps.activeSection)
      if (activeColor !== activeColorState) {
        this.setActiveColor(activeColor)
      }
    }
  }
  componentDidUpdate() {
    const { activeSection, scrolling } = this.props
    if (!scrolling) {
      switch (window.location.hash) {
        case '#municipalika':
          if (activeSection !== 1) this.props.goToSection(1)
          break
        case '#capex':
          if (activeSection !== 2) this.props.goToSection(2)
          break
        case '#india-international-property-mart':
          if (activeSection !== 3) this.props.goToSection(3)
          break
        case '#urban-news-digest':
          if (activeSection !== 5) this.props.goToSection(5)
          break
        default:
          if (activeSection !== 0) this.props.goToSection(0)
          break
      }
    }
  }
  setHeaderColor = whiteLogo => this.setState({ whiteLogo })
  updateHeaderColor = active => {
    switch (active) {
      case 0:
        return true
      case 1:
        return false
      case 2:
        return false
      case 3:
        return false
      case 4:
        return false
      case 5:
        return false
      case 6:
        return false
      default:
        return false
    }
  }
  getInActiveColor = active => {
    switch (active) {
      case 1:
        return 'grey'
      case 2:
        return 'grey'
      case 3:
        return 'grey'
      case 4:
        return 'grey'
      case 5:
        return 'grey'
      case 6:
        return 'grey'
      default:
        return 'grey'
    }
  }
  getActiveColor = active => {
    switch (active) {
      case 1:
        return '#004a82'
      case 2:
        return '#007a9f'
      case 3:
        return '#bc4a51'
      case 4:
        return '#4d4d4d'
      case 5:
        return '#77b236'
      case 6:
        return '#77b236'
      default:
        return 'grey'
    }
  }
  setActiveColor = activeColor => this.setState({ activeColor })

  render() {
    const { whiteLogo, activeColor: activeColorState } = this.state
    const { activeSection } = this.props

    return (
      <Layout whiteLogo={whiteLogo}>
        {activeSection !== 0 && (
          <Scroller.DotGroup
            className="dots"
            renderDot={({ active, index, ...props }) => (
              <button
                className="dot"
                {...props}
                style={{
                  backgroundColor:
                    index === active
                      ? this.getActiveColor(active)
                      : this.getInActiveColor(active),
                }}
              />
            )}
          />
        )}
        {/* {activeSection !== 0 && (
          <Scroller.NextButton className="half-circle">
            <div
              className="bottom-arrow"
              style={{ backgroundColor: activeColorState }}
            >
              <i>
                <FaChevronDown color="#fff" />
              </i>
            </div>
          </Scroller.NextButton>
        )} */}
        <Scroller.Sections>
          <Scroller.Section>
            <HomeSection
              id="urban-development"
              Heading="URBAN DEVELOPMENT"
              links={[
                {
                  to: '/urban-development#municipalika',
                  name:
                    'FUTURE CITIES MEGA SHOW: 1 VENUE, 3 INTERCONNECTED SHOWS',
                },
              ]}
              links1={[
                { to: 'urban-development#municipalika', name: 'MUNICIPALIKA' },
                { to: 'urban-development#capex', name: 'CAPEx' },
                {
                  to: 'urban-development#india-international-property-mart',
                  name: 'AAM',
                },
                { to: 'urban-development#urban-news-digest', name: 'UND' },
              ]}
              button={[
                { to: '/urban-development#municipalika', name: 'Next Show Date - 28, 29 & 30 November 2024' },
              ]}
              img={udimg}
            />
          </Scroller.Section>
          <Scroller.Section className="mobile-default-scroll">
            <DetailsSection
              id="municipalika"
              customImage={
              <div className='d-flex'>
                 <img className="img-fluid img-logo img-fluid-with-border" src={municipalika} alt={"municipalika"} /> 
                 <div className='d-flex flex-column justify-content-between'>
                 <div className='urban-partner-logo-label'>Co-Located With</div>
                 <img className="img-fluid img-logo img-fluid-with-label" src={bharatRuralExpo} alt={"bharatRuralExpo"} />
                 </div>
                 </div>
                 }
              buttonText="Visit Website"
              buttonLink="https://www.municipalika.com/"
              stats={[
                { number: '200+', text: 'Exhibitors' },
                { number: '300+', text: 'Cities & Towns' },
                { number: '10,000+ ', text: 'Visitors' },
              ]}
              youtubeurl="https://www.youtube.com/embed/xIFtrpoSNRA?si=r6FDFC4Gm8Bzj9iW"
              headerText="Municipalika is an international show to evolve, evaluate and share policies, strategies, cutting edge technologies and innovations towards Smart and Sustainable living in cities. Through exhibitions and conferences, it provides a mega-networking forum for governments, experts, entrepreneurs and service providers to collectively find solutions to urban challenges."
            />
          </Scroller.Section>
          <Scroller.Section className="mobile-default-scroll">
            <DetailsSection
              id="capex"
              src={capex}
              imgurl={capexbg}
              buttonText="Visit Website"
              buttonLink="https://capex.co.in"
              headerText="CAPEx, within the Future Cities exhibition, showcases revolutionary architectural concepts, advanced construction materials, and game-changing technologies. At its 17th edition, CAPEx (Construction, Architecture, Planning, and Engineering Expo) revealed innovative building materials and technologies, highlighting speedy construction, green products, and cutting-edge materials. The City Development Investment Conclave focuses on raising financial resources through public, private, and public-private partnerships. Discussions at CAPEx are aimed at enhancing urban development schemes while embracing sustainability goals. Overall, CAPEx prioritizes sustainability, efficiency, and pioneering solutions in construction and urban development."
            />
          </Scroller.Section>
          <Scroller.Section className="mobile-default-scroll">
            <DetailsSection
              id="india-international-property-mart"
              src={AAM}
              imgurl={AAMbg}
              buttonText="Visit Website"
              buttonLink="http://iipm.asia"
              headerText="The Architecture in The Age of Millennials (AAM) highlights opportunities and challenges for burgeoning architects. AAM within Municipalika gathers thought leaders—architects, designers, engineers—to explore transformations aligned with millennial preferences. It reflects industries adapting to meet millennials’ tech-savvy, eco-conscious demands. Showcasing designs and fostering discussions, AAM amplifies emerging architects’ perspectives, aspirations, and challenges. Offering a forward-looking vision, it showcases how this generation reshapes the architectural landscape in the millennial era."
              isButtonShow={false}
            />
          </Scroller.Section>
          <Scroller.Section className="mobile-default-scroll">
            <TheyTrustUs11 />
          </Scroller.Section>
          <Scroller.Section className="mobile-default-scroll pb-0">
            <DetailsSection
              id="urban-news-digest"
              src={und}
              imgurl={UNDbg}
              buttonText="Visit Website"
              buttonLink="http://www.urbannewsdigest.in/"
              headerText="Urban News Digest collates and presents news and features from the urban sector from all over India and the world. It is India’s first media platform for urban development stakeholders, available in both print and online editions."
              isButtonShow={false}
            />
            <Footer />
          </Scroller.Section>
        </Scroller.Sections>
      </Layout>
    )
  }
}
export default withScroll({ sections: 6 })(Urban)
