import React from 'react'
import urban1 from '../../images/LOGO/urban1.jpg'
import urban2 from '../../images/LOGO/urban2.jpg'
import urban3 from '../../images/LOGO/urban3.jpg'
import urban4 from '../../images/LOGO/urban4.jpg'
import './TimeLine.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

const TimeLine1 = ({ id, stats, buttonName }) => (  
  <section id="timeline1">
    <div className="otm-home1">
      <div className="event-time">
        <div className="col-sm-12">
          <p className="pag-line-trust">THEY TRUST US</p>
        </div>

        <Slider {...settings}>
          <div>   
            <img alt="" className="img-fluid partner-img" src={urban1} />
          </div>
          <div>
            <img alt="" className="img-fluid partner-img" src={urban2} />
          </div>
          <div>
            <img alt="" className="img-fluid partner-img" src={urban3} />
          </div>
          <div>
            <img alt="" className="img-fluid partner-img" src={urban4} />
          </div>
      {/* <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="main-circle">
        <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={turkey} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={greece} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={egypt} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={indo} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={china} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={thai} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={nepal} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={rwanda} />
                </div>
              </div>
            </div>
        </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kenya} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maharastra}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={hplogo} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={kores} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={goa} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={enjoycity}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taiwan} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={maldives}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={indialogo}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={gujarat} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={cambodia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bhutan} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bahrain} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={airindia}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={cox} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={westbangal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-circle">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={tbo} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={srilanka}
                  />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={taj} />
                </div>
                <div className="col-sm-3 divide">
                  <img
                    alt=""
                    className="img-fluid partner-img"
                    src={malaysai}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={usa} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={bang} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={newZealand} />
                </div>
                <div className="col-sm-3 divide">
                  <img alt="" className="img-fluid partner-img" src={otoai} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Slider>

        
      </div>
    </div>
  </section>
)

export default TimeLine1
